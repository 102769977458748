
$(document).ready(function () {
    var cookiesUserAcceptance = localStorage.getItem('cookiesUserAcceptance');
    var cookiesPopUpElement = $('.cookies-policy-container');
    if (!cookiesUserAcceptance) {
        cookiesPopUpElement.removeClass('d-none');
    }
    $('.cookies-close-popup').on('click', function () {
        cookiesPopUpElement.addClass('d-none');
        localStorage.setItem('cookiesUserAcceptance', true);
    });
});